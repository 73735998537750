var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as Yup from 'yup';
export var generalCashRegisterValidation = function (isSuperAdmin) {
    return Yup.object().shape(__assign(__assign({ amount: Yup.number().moreThan(0).required('Amount is required'), notes: Yup.string().optional() }, (isSuperAdmin
        ? {
            office: Yup.object().nullable().required('Office is required'),
        }
        : {})), { cashedTo: Yup.object().nullable().required('cashed By is required') }));
};
